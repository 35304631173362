import React from "react";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const RewardItemComponent = ({ galleryItem }) => {
  return (
    <>
      <div className=" lg:px-24 md:px-10 sm:px-20 px-10 pb-20 z-10">
        <div className="flex flex-wrap  justify-center lg:justify-start">
          {galleryItem.map((curr) => {
            return (
              <div key={curr._key} className=" py-4 md:py-0">
                <GatsbyImageCustom
                  id={curr.reward_category_image.asset._ref}
                  alt={curr.reward_category_image_alt}
                  className="h-full w-80 xl:w-full "
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RewardItemComponent;
