import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../Layout/Layout";
import RewardCategorySection from "../Rewards/RewardCategorySection";
import RewardTestimonialSection from "../Rewards/RewardTestimonialSection";
import CommomHeaderComponent from "../Common/CommomHeaderComponent";
import GraphQLErrorList from "../GraphQLErrorList/GraphQLErrorList";

export const query = graphql`
  query RewardPageTemplateQuery($id: String!) {
    route: sanityRewardspage(id: { eq: $id }) {
      slug {
        current
      }
      title
    }
  }
`;

const RewardsPage = (props) => {
  const { data, errors } = props;
  if (errors) {
    return (
      // <Layout location={props.location}>
      <GraphQLErrorList errors={errors} />
      // </Layout>
    );
  }
  const page = data.page || data.route.page;
  const content = (page._rawPagebuildersection || [])
    .filter((c) => !c.disabled)
    .map((rewardsec, i) => {
      let el = null;
      switch (rewardsec._type) {
        case "common_banner_section":
          el = (
            <CommomHeaderComponent
              key={rewardsec._key}
              {...rewardsec}
              title={rewardsec.common_banner_title_highlighted}
              content={
                rewardsec.common_banner_desc
                  ? rewardsec.common_banner_desc[0].children[0].text
                  : null
              }
              imgsrc={rewardsec.common_banner_image.asset._ref}
            />
          );
          break;
        case "reward_category_section":
          el = <RewardCategorySection key={rewardsec._key} {...rewardsec} />;
          break;
        case "reward_testimonial_section":
          el = <RewardTestimonialSection key={rewardsec._key} {...rewardsec} />;
          break;
        default:
          el = null;
      }
      return el;
    });

  return (
    <>
      <Layout location={props.location}>
        <Helmet>
          <title>{data.page.meta_title || data.page.title}</title>
          <meta
            property="og:title"
            content={data.page.meta_title || data.page.title}
          />
          <meta
            property="twitter:title"
            content={data.page.meta_title || data.page.title}
          />
          <meta name="description" content={data.page.meta_desc} />
          <meta property="og:description" content={data.page.meta_desc} />
          <meta property="twitter:description" content={data.page.meta_desc} />
          <meta
            name="theme-color"
            content="#144a95"
            media="(prefers-color-scheme: light)"
          />
          <meta
            name="theme-color"
            content="#144a95"
            media="(prefers-color-scheme: dark)"
          />
          <meta name="theme-color" content="#144a95" />
        </Helmet>
        <div className="z-10">{content}</div>
      </Layout>
    </>
  );
};

export default RewardsPage;
