import React from "react";

const RewardListComponent = ({ filterReward, title, listItem }) => {
  return (
    <>
      <div className=" text-center lg:space-x-6 md:space-x-5 sm:space-x-2 space-x-0 lg:px-16 md:px-16 px-6 py-10 z-10">
        {title.map((curEle, index) => {
          return (
            <button
              className={` ${
                curEle.reward_category_name === listItem
                  ? "border-b-4 border-secondary-darkblue-500 border-transparent "
                  : ""
              } p-2  lg:text-2xl text-xl font-medium focus:outline-none `}
              key={index}
              onClick={() => filterReward(curEle.reward_category_name)}
            >
              {curEle.reward_category_name}
            </button>
          );
        })}
      </div>
    </>
  );
};

export default RewardListComponent;
