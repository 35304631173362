import { graphql } from "gatsby";
import * as React from "react";
import Errors from "../Components/Errors/Errors";
import RewardsPage from "../Components/Templates/RewardsPage";

export const query = graphql`
  query RewardpageQuery {
    page: sanityRewardspage(
      _id: { eq: "6f5dbadd-fe7c-417d-ad80-86fec69c2753" }
      slug: { current: {} }
    ) {
      meta_title
      meta_desc
      title
      _id
      _rawPagebuildersection
    }
  }
`;

const rewards = (props) => {
  const { data, errors,location } = props;
  if (errors) {
    return <Errors errors={errors} />;
  }

  return <RewardsPage data={data} location={location}/>;
};

export default rewards;
