import React from "react";
import Slider from "react-slick";
import "./reward.css"
const RewardTestimonialSection = (props) => {
  // console.log(props, "gg");
  const settings = {
    infinite: true,
    autoplay: false,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",

    // speed: 500,
    arrows: false,
    centerMode: true,

    className: "centered-slider",

    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

          centerPadding: "300px",
        },
      },

      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <>
      <div className="z-10  bg-secondary-gray-300 ">
        <div className="py-20 testimonial-section-slider ">
          <div>
            <h1 className="text-center text-secondary-darkpurple-500 lg:text-5xl text-2xl font-bold">
              {props.reward_testimonial_hightlightedtitle}
            </h1>
          </div>

          <div className="text-center">
            <Slider {...settings}>
              {props.reward_testimonial_section.map((xx, idx) => {
                return (
                  <div key={idx} className=" pt-20 p-4">
                    <div className="lg:py-14 py-7 bg-gray-100 text-black rounded-md  transition-all ease-in-out transform scale-90 card">
                      <div className="lg:px-16 px-4">
                        <span className="text-5xl">“</span>
                        <p className="text-xl italic">
                          {xx.reward_testimonial_desc}
                        </p>
                        <h1 className="text-2xl font-bold pt-10">
                          <span>-</span> {xx.reward_testimonial_title}
                        </h1>
                      </div>
                      <div className="border relative lg:top-14 top-10 p-2 bg-secondary-golden-500 border-secondary-golden-500"></div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardTestimonialSection;
