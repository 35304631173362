import React from "react";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

// import { graphql, useStaticQuery } from "gatsby";
const CommomHeaderComponent = ({ title, content, imgsrc, ImageEffectId }) => {

  console.log(title,imgsrc, "title");
  // const Image = useStaticQuery(graphql`
  //   query {
  //     allSanityImageAsset {
  //       nodes {
  //         _id
  //         localFile {
  //           publicURL
  //           childImageSharp {
  //             gatsbyImageData
  //             fluid {
  //               src
  //             }
  //           }
  //         }
  //         gatsbyImageData
  //       }
  //     }
  //   }
  // `);
  // return <div>{console.log(Image.allSanityImageAsset.nodes.find(aa=>aa.id===id).gatsbyImageData,id, "Image")}</div>;

  // const ImageData = Image.allSanityImageAsset.nodes.find(
  //   (aa) => aa._id === ImageEffectId
  // );

  return (
    <>
      <div
        className="relative  bg-cover z-10 bg-white"
        // style={{ backgroundImage: `${ImageData}` }}
      >
        {/* <GatsbyImageCustom id={imgsrc}  /> */}
        {/* <img src={imgsrc} /> */}
        <GatsbyImageCustom
          style={{ height: "500px" }}
          id={imgsrc}
          alt="image header"
          className=" w-full filter object-cover"
        />
        <div className="mx-auto max-w-6xl">
          <div className=" absolute left-0 right-0 top-0 bottom-0 m-auto flex justify-center items-center">
            <div className="px-4">
              <div className=" font-bold lg:text-6xl md:text-4xl  text-2xl text-white text-center">
                {title}
              </div>
              <div className="font-normal text-white lg:text-2xl  text-sm md:max-w-2xl mx-auto mt-7 text-center">
                {content}
              </div>
            </div>
            {/* <div>
            <div className="absolute lg:top-96 md:top-60 top-36 md:inset-x-2/1 left-40 px-4">
              <button>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="text-white"
                  size="lg"
                />
              </button> 
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommomHeaderComponent;
