import React, { useState } from "react";
import RewardItemComponent from "./RewardItemComponent";
import RewardListComponent from "./RewardListComponent";

const RewardCategorySection = (props) => {
  // console.log(props.reward_category_section, "props.reward_category_section");
  // console.log(props, "all");

  const [item, setItem] = useState(
    props.reward_gallery_section
      ? props.reward_gallery_section[0].reward_category_name
      : undefined
  );

  const filterReward = (category) => {
    setItem(category);
  };
  // console.log(props.reward_gallery_section, "jj");
  // console.log(item, "item");
  return (
    <>
      <div className="  lg:mt-10 mt-0 mx-auto max-w-6xl">
        <RewardListComponent
          filterReward={filterReward}
          title={props.reward_gallery_section}
          listItem={item}
        />

        <RewardItemComponent
          galleryItem={
            props.reward_gallery_section.find((aa) =>
              item ? aa.reward_category_name === item : true
            ).reward_category_fields
          }
        />
      </div>
    </>
  );
};

export default RewardCategorySection;
